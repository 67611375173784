<template>
  <v-navigation-drawer
          v-model="drawer"
          app
          clipped
  >
    <v-list dense>

      <v-list-item to="/dashboard/main">
        <v-list-item-action >
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Dashboard
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/dashboard/enterprises">
        <v-list-item-action >
          <v-icon>mdi-domain</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Enterprises
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    drawer: true,
  }),
    computed:{
  },
  created() {
    window.getApp.$on("APP_DRAWER_TOGGLED", () => {
      this.drawer = !this.drawer;
    });
  },
};
</script>

<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-btn block outlined @click="reloadPage()">
      Rafraîchir
      <v-icon
          right
      >
        mdi-refresh
      </v-icon>
    </v-btn>
    <v-card
        outlined
        tile>
      <v-container grid-list-lg>
        <v-layout row wrap>

          <v-flex xs12 md6 class="">
            <v-flex xs12 class="pa-0">
              <v-card style="">
                <v-card-text class="">
                  <p style="color:black;"><strong>En attente de paiement :</strong> {{ reseller.balance }} €</p>
                  <p style="color:black;"><strong>Taux de résiliation et d'annulation :</strong>
                    &nbsp;
                    <v-chip
                        :color="getColor(resellerTotalRate(reseller))"
                        dark
                    >
                      {{ resellerTotalRate(reseller) }}%
                    </v-chip>
                  </p>
                  <p style="color:black;"><strong>Sales Portal: </strong> <a :href="salesPortalURL" target="_blank">{{ salesPortalURL }}</a> </p>
                  <p style="color:black;"><strong>Sales Portal Code d'accès: </strong> {{ reseller.access_code }} </p>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-flex>

          <v-flex xs12 md6 class="">
            <v-flex xs12 class="pa-0">
              <v-card style="">
                <v-card-text class="text-center">
                  <h2 class="pa-4">
                    <a href="https://www.youtube.com/watch?v=BuiTAGKm77E" target="_blank">
                    Tutoriel vente partenaire
                    </a>
                    <v-icon color="red" x-large>
                      mdi-youtube
                    </v-icon>

                  </h2>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-flex>

          <v-flex xs12 md12 class="">
            <v-flex xs12 class="pa-0">
              <v-card style="">
                <v-toolbar
                    class="text-h5"
                    color="primary"
                    dark
                >
                <div class="d-flex justify-space-between" style="width: 100%; align-items: center">
                  Codes promo et liens pour la vente à vos clients
                  <v-btn tile outlined class="my-2" @click="cuponModel.id= null;openAddCupon()">
                    <v-icon left>
                      mdi-plus
                    </v-icon>
                    Créer
                  </v-btn>
                </div>
                </v-toolbar>
                <v-card-text class="">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-center">
                          Code promo
                        </th>
                        <th class="text-center">
                          Lien
                        </th>
                        <th class="text-center">
                          Jours d'essai
                        </th>
                        <th class="text-center">
                          Actions
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(c, index) in cupons" :key="index" class="text-center">
                        <td><strong>{{ c.code }}</strong></td>
                        <td><a :href="getTechSecurityLink+'/partenaire/'+c.code" target="_blank">{{
                            getTechSecurityLink
                          }}/partenaire/{{ c.code }}</a></td>
                        <td>{{ c.days_trial }}</td>
                        <td>
                      <v-btn icon color="primary" @click="openEditCuponDialog(c)">
                        <v-icon dark small>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                      <v-btn icon color="red" @click="deleteCupon(c.id)">
                        <v-icon dark small>
                          mdi-close
                        </v-icon>
                      </v-btn>
                </td>
                      </tr>

                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-flex>

          <v-flex xs12 md12 class="">
            <v-flex xs12 class="pa-0">
              <v-card style="">
                <v-toolbar
                    class="text-h5"
                    color="primary"
                    dark>
                    <div class="d-flex justify-space-between " style="width: 100%; align-items: center">
                      Commissions
                      <v-btn tile outlined class="my-2" @click="downloadComissionsData()">
                        <v-icon left>
                          mdi-export
                        </v-icon>
                        Exporter
                      </v-btn>
                    </div>
                </v-toolbar>
                <v-card-text class="pt-3 pa-0">
                  <v-data-table :headers="comissionsHeaders"
                                :items="comissions"
                                :loading="loading"
                                :options.sync="comissionsOptions"
                                :server-items-length="comissionsTotal"
                                :footer-props="{
                                      itemsPerPageOptions: rowsPerPageItems,
                                      itemsPerPageText: 'Lignes par page:'}"
                                class="elevation-1" loading-text="Loading...">

                    <template v-slot:item.first_comission_paid="{ item }">
                      {{ item.first_comission_paid === 'Yes' ? 'Oui' : 'Non' }}
                    </template>
                    <template v-slot:item.recurring_comission_active="{ item }">
                      <v-chip
                          :color="item.recurring_comission_active === 'Yes'?'green':'red'"
                          dark
                          label
                      >
                        {{ item.recurring_comission_active === 'Yes' ? 'Actif' : 'Inactif' }}
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-flex>

          <v-flex xs12 md12 class="">
            <v-flex xs12 class="pa-0">
              <v-card style="">
                <v-toolbar
                    class="text-h5"
                    color="primary"
                    dark>
                  Mes versements de rémunérations et mes informations bancaire
                </v-toolbar>
                <v-card-text class="">
                  <v-btn block outlined @click="openStripeAccount()">
                    ACCÉDER
                    <v-icon
                        right
                    >
                      mdi-open-in-new
                    </v-icon>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-flex>

          <v-flex xs12 md6 class="">
            <v-flex xs12 class="pa-0">
              <v-card style="">
                <v-toolbar
                    class="text-h5"
                    color="primary"
                    dark>
                  Contact partenaire
                </v-toolbar>
                <v-card-text class="">
                  <p style="color:black;"><strong>Téléphone:</strong> 07 49 64 39 65 (Du mardi au
                    samedi de 10h à 13h et de 14h30 à 19h) </p>
                  <p style="color:black;"><strong>Adresse mail:</strong> partenaire@techsecurity.tech
                  </p>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-flex>

          <!--          <v-flex xs12 md6 class="">-->
          <!--            <v-flex xs12 class="pa-0">-->
          <!--              <v-card style="">-->
          <!--                <v-toolbar-->
          <!--                    class="text-h5"-->
          <!--                    color="primary"-->
          <!--                    dense-->
          <!--                    dark>-->
          <!--                  Contrat-->
          <!--                </v-toolbar>-->
          <!--                <v-card-text class="">-->

          <!--                </v-card-text>-->
          <!--              </v-card>-->
          <!--            </v-flex>-->
          <!--          </v-flex>-->

          <v-flex xs12 md6 class="">
            <v-flex xs12 class="pa-0">
              <v-card style="">
                <v-toolbar
                    class="text-h5"
                    color="primary"
                    dark
                >
                  Supports de vente
                </v-toolbar>
                <v-card-text class="">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                      <tr v-for="(f, index) in supportFiles" :key="index" class="text-left" @click="downloadFile(f.id, f.name)" style="cursor: pointer">
                        <td><strong>{{ f.name }}</strong></td>
                        <td>
                          <v-btn
                              tile
                              small
                              color="success"
                              class="float-right"
                              @click="downloadFile(f.id, f.name)"
                          >
                            <v-icon>
                              mdi-download
                            </v-icon>
                          </v-btn>
                        </td>
                      </tr>

                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-flex>





        </v-layout>
      </v-container>
    </v-card>


    <v-dialog
        v-model="addCuponDialog"
        persistent
        scrollable
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Create Cupon</span>
        </v-card-title>
        <v-card-text >
          <v-container>
            <h3 light>Cupon Fields</h3>
            <v-divider/>
            <br/>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    label="Code"
                    required
                    :disabled="cuponModel.id != null"
                    v-model="cuponModel.code"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    label="Days of Trial"
                    type="number"
                    value="30"
                    disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <br/>
            <br/>
            <h3 light>Seller Fields</h3>
            <v-divider/>
            <br/>
      
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field label="First Name" v-model="cuponModel.seller_name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
              <v-text-field label="Surname" v-model="cuponModel.seller_lastname" ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
              <v-text-field label="Shop" v-model="cuponModel.shop" ></v-text-field>
              </v-col>
            </v-row>

            <br/>
            <h3 light>Comissions Fields (€)</h3>
            <v-divider/>
            <br/>
             <v-row>
              <v-col cols="12" sm="6" v-for="product in subscriptionTypes" :key="product.value">
              <v-text-field :label="`${product.text} Comission`" type="number" v-model="cuponModel.seller_comission[product.value]" ></v-text-field>
              </v-col>
            </v-row>
            <v-divider/>
            <br/>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Notification Email" v-model="cuponModel.notify_email"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="addCuponDialog = false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="createCupon"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const Swal = require('sweetalert2')
// const moment = require('moment');
export default {
  name: 'Main',

  data: () => ({
    loading: false,
    reseller: [],
    cupons: [],
    comissions: [],
    comissionsTotal: 0,
    comissionsOptions: {
      itemsPerPage: 5,
    },
    rowsPerPageItems: [5, 10, 15, 25, 50],
    comissionsHeaders: [
      {
        text: 'Date d\'achat',
        align: 'left',
        value: 'buy_date',
      },
      {
        text: 'Type de commission',
        align: 'left',
        value: 'comission_type',
      },
      {
        text: 'Code promo',
        align: 'left',
        value: 'cupon',

      },
      {
        text: 'Produit d\'origine',
        align: 'left',
        value: 'original_product',
        width: 300,
      },
      {
        text: 'Date de la première rémunération',
        align: 'left',
        value: 'first_comission_date',

      },
      {
        text: 'Montant de la première rémunération',
        align: 'left',
        value: 'first_comission_amount',

      },
      {
        text: 'Première rémunération payée',
        align: 'left',
        value: 'first_comission_paid',

      },
      {
        text: 'Prochaine date de rémunération',
        align: 'left',
        value: 'next_recurring_comission_date',

      },
      {
        text: 'Montant de la prochaine rémunération',
        align: 'left',
        value: 'recurring_comission_amount',
      },
      {
        text: 'Statut rémunération',
        align: 'left',
        value: 'recurring_comission_active',
      },
    ],
    supportFiles: [],
    addCuponDialog: false,
    subscriptionTypes: [
      {value: 'FAMILY_1', text: 'Family 1 Device'},
      {value: 'FAMILY_2', text: 'Family 3 Devices'},
      {value: 'FAMILY_3', text: 'Family 5 Devices'},
      {value: 'FAMILY_4', text: 'Family 10 Devices'},
      {value: 'PROFESSIONAL_1', text: 'Professional 5 Devices'},
      {value: 'PROFESSIONAL_2', text: 'Professional 10 Devices'},
      {value: 'PROFESSIONAL_3', text: 'Professional 20 Devices'},
    ],
    cuponModel: {
      id: null,
      code: '',
      seller_name: null, 
      seller_lastname: null, 
      shop: null, 
      seller_comission: {},
      notify_email: null,
    },
  }),
  watch: {
    comissionsOptions: {
      handler() {
        this.getResellerComissions()
      },
      deep: true,
    },
  },
  computed: {
    getTechSecurityLink() {
      return process.env.VUE_APP_TECHSECURITY_URL
    },
    salesPortalURL(){
      return process.env.VUE_APP_URL + '/sales-portal/' + this.reseller.id
    }
  },
  methods: {
    getColor(val) {
      if (val > 74) {
        return 'green'
      }
      return val > 49 ? 'orange' : 'red'
    },
    resellerTotalRate(reseller) {
      return ((reseller.active_comissions + reseller.inactive_comissions) === 0 ? 0 : (reseller.active_comissions * 100) / (reseller.active_comissions + reseller.inactive_comissions)).toFixed(2);
    },
    openStripeAccount() {
      window.open(this.$store.state.stripe_link, '_blank').focus();
    },
    getResellerCupons() {
      this.loading = true;
      this.axios.get(process.env.VUE_APP_API_URL + "/cupons")
          .then(response => {
            this.cupons = response.data;
            (this.cupons || []).map(c => {
              c.seller_comission = JSON.parse(c.seller_comission);
            })
            this.loading = false;

          }).catch(error => {
            this.loading = false;
            console.log(error.response)
          }
      );
    },
    getResellerComissions() {
      this.loading = true;
      this.axios.get(process.env.VUE_APP_API_URL + "/resellerComissions?items_per_page=" + this.comissionsOptions.itemsPerPage + "&page=" + this.comissionsOptions.page)
          .then(response => {
            this.comissions = response.data.data;
            this.comissionsTotal = response.data.meta.total
            this.loading = false;

          }).catch(error => {
            this.loading = false;
            console.log(error.response)
          }
      );
    },

    getReseller() {
      this.loading = true;
      this.axios.get(process.env.VUE_APP_API_URL + "/reseller")
          .then(response => {
            this.reseller = response.data.data
            console.log(response.data);
            this.loading = false;

          }).catch(error => {
            this.loading = false;
            console.log(error.response)
          }
      );
    },
    reloadPage() {
      window.location.reload();
    },
    getFiles() {
      this.axios.get(process.env.VUE_APP_API_URL + "/files")
          .then(response => {
            this.loading = false;
            this.supportFiles = response.data.files
          }).catch(error => {
        this.loading = false;
        console.log(error.response)
      });
    },
    downloadFile(id, name) {

      this.axios.get(process.env.VUE_APP_API_URL + "/file/download/" + id, {
        headers: {
          'Content-Disposition': "attachment; filename=users.xlsx",
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer'
      })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name); //or any other extension
            document.body.appendChild(link);
            link.click();
          }).catch(error => {
            console.log(error)
          }
      );

    },
    openAddCupon() {
      this.cuponModel.id = null,
      this.cuponModel.code = '',
      this.cuponModel.seller_name = null, 
      this.cuponModel.seller_lastname = null, 
      this.cuponModel.shop = null, 
      this.cuponModel.seller_comission = {},
      this.cuponModel.notify_email = null,

      this.addCuponDialog = true;
    },
    openEditCuponDialog(cupon) {
      this.cuponModel.id = cupon.id;
      this.cuponModel.code = cupon.code;
      this.cuponModel.seller_name = cupon.seller_name, 
      this.cuponModel.seller_lastname = cupon.seller_lastname, 
      this.cuponModel.shop = cupon.shop, 
      this.cuponModel.seller_comission = cupon.seller_comission ? cupon.seller_comission : {},
      this.addCuponDialog = true;
    },
    createCupon() {
      if (!this.cuponModel.id) {
        this.loading = true;
        const data = {
          reseller_id: this.reseller.id,
          code: this.cuponModel.code,
          days_trial: 30,
          expiration_date: null,
          comission_type: 'TYPE_A',
          seller_name: this.cuponModel.seller_name, 
          seller_lastname: this.cuponModel.seller_lastname, 
          shop: this.cuponModel.shop, 
          seller_comission:  Object.keys(this.cuponModel.seller_comission).length ? JSON.stringify(this.cuponModel.seller_comission) : null,
          notify_email: this.cuponModel.notify_email
        };

        this.axios.post(process.env.VUE_APP_API_URL + "/cupon/create", data)
            .then(response => {
              this.cupons.push(response.data);
              console.log(response.data);
              this.loading = false;
              Swal.fire({
                text: 'Cupon Created!',
                icon: 'success',
                confirmButtonColor: '#77BB22',
                confirmButtonText: 'Close',
                showCloseButton: true
              })
              this.addCuponDialog = false;

            }).catch(() => {
              this.loading = false;
              Swal.fire({
                text: 'Error creating cupon!',
                icon: 'error',
                confirmButtonColor: '#77BB22',
                confirmButtonText: 'Close',
                showCloseButton: true
              })
            }
        );
      } else {
        const data = {
          cupon_id: this.cuponModel.id,
          code: this.cuponModel.code,
          days_trial: 30,
          expiration_date: null,
          comission_type: 'TYPE_A',
          seller_name: this.cuponModel.seller_name, 
          seller_lastname: this.cuponModel.seller_lastname, 
          shop: this.cuponModel.shop, 
          seller_comission:  Object.keys(this.cuponModel.seller_comission).length ? JSON.stringify(this.cuponModel.seller_comission) : null,
          notify_email: this.cuponModel.notify_email
        };

        this.axios.post(process.env.VUE_APP_API_URL + "/cupon/update", data)
            .then(() => {
              let index = this.cupons.indexOf(this.cupons.find(c => c.id === this.cuponModel.id));
              this.cupons[index].code = this.cuponModel.code;
              this.cupons[index].seller_name = this.cuponModel.seller_name, 
              this.cupons[index].seller_lastname = this.cuponModel.seller_lastname, 
              this.cupons[index].shop = this.cuponModel.shop, 
              this.cupons[index].seller_comission = this.cuponModel.seller_comission,
              this.loading = false;
              Swal.fire({
                text: 'Cupon Updated!',
                icon: 'success',
                confirmButtonColor: '#77BB22',
                confirmButtonText: 'Close',
                showCloseButton: true
              })
              this.addCuponDialog = false;

            }).catch(() => {
              this.loading = false;
              Swal.fire({
                text: 'Error updating cupon!',
                icon: 'error',
                confirmButtonColor: '#77BB22',
                confirmButtonText: 'Close',
                showCloseButton: true
              })
            }
        );
      }
    },
    deleteCupon(id) {
      this.loading = true;
      this.axios.post(process.env.VUE_APP_API_URL + "/cupon/delete/" + id)
          .then(response => {
            let index = this.cupons.indexOf(this.cupons.find(c => c.id === id));
            console.log(index);
            this.cupons.splice(index, 1);
            console.log(response.data);
            this.loading = false;
            Swal.fire({
              text: 'Cupon Deleted!',
              icon: 'success',
              confirmButtonColor: '#77BB22',
              confirmButtonText: 'Close',
              showCloseButton: true
            })
          }).catch(error => {
            console.log(error.response)
            this.loading = false;
            Swal.fire({
              text: 'Error deleting cupon!',
              icon: 'error',
              confirmButtonColor: '#77BB22',
              confirmButtonText: 'Close',
              showCloseButton: true
            })
          }
      );
    },
    downloadComissionsData() {
      this.axios.get(process.env.VUE_APP_API_URL + "/export/comissionsData/" + this.reseller.id, {
        headers: {
                'Content-Disposition': "attachment; filename=users.xlsx",
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer'
      })
          .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'comissions.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
          }).catch(error => {
              console.log(error)
          }
      );
    
    },
  },
  mounted() {

  },
  created() {
    this.getResellerCupons();
    this.getResellerComissions();
    this.getReseller();
    this.getFiles();
  }

}
</script>

<style>

</style>
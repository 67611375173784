import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router';
import store from './vuex/index'
window.axios = require('axios')

import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.headers.common['Authorization'] = store.state.sessionToken

Vue.use(VueAxios, axios)

Vue.config.productionTip = false
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
const drawer = Vue.component('drawer', require('./components/template/drawer.vue').default);
const toolbar = Vue.component('toolbar', require('./components/template/toolbar.vue').default);

new Vue({
  vuetify,
  router,
  store, 
  components: {
    drawer, toolbar
  },
  created: function () {
    window.getApp = this;
  },
  render (h) {
    return h(App)
  }
}).$mount('#app')

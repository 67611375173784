<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-card
        outlined
        tile>
      <v-container grid-list-lg>
        <v-layout row wrap>

          <v-flex xs12 md12 class="">
            <v-flex xs12 class="pa-0">
              <v-card style="">
                <v-toolbar
                    class="text-h5"
                    color="primary"
                    dark>
                  Enterprises
                </v-toolbar>
                <v-card-text class="pt-3 pa-0">
                  <v-data-table :headers="comissionsHeaders"
                                :items="comissions"
                                :loading="loading"
                                :options.sync="comissionsOptions"
                                :server-items-length="comissionsTotal"
                                :footer-props="{
                                      itemsPerPageOptions: rowsPerPageItems,
                                      itemsPerPageText: 'Lignes par page:'}"
                                class="elevation-1" loading-text="Loading...">

                    <template v-slot:item.first_comission_paid="{ item }">
                      {{ item.first_comission_paid === 'Yes' ? 'Oui' : 'Non' }}
                    </template>
                    <template v-slot:item.recurring_comission_active="{ item }">
                      <v-chip
                          :color="item.recurring_comission_active === 'Yes'?'green':'red'"
                          dark
                          label
                      >
                        {{ item.recurring_comission_active === 'Yes' ? 'Actif' : 'Inactif' }}
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-flex>

        </v-layout>
      </v-container>
    </v-card>

  </div>
</template>

<script>
// const Swal = require('sweetalert2')
// const moment = require('moment');
export default {
  name: 'Main',

  data: () => ({
    loading: false,
    comissions: [],
    comissionsTotal: 0,
    comissionsOptions: {
      itemsPerPage: 5,
    },
    rowsPerPageItems: [5, 10, 15, 25, 50],
    comissionsHeaders: [
      {
        text: 'Date d\'achat',
        align: 'left',
        value: 'buy_date',
      },
      {
        text: 'Type de commission',
        align: 'left',
        value: 'comission_type',
      },
      {
        text: 'Code promo',
        align: 'left',
        value: 'cupon',

      },
      {
        text: 'Produit d\'origine',
        align: 'left',
        value: 'original_product',
        width: 300,
      },
      {
        text: 'Date de la première rémunération',
        align: 'left',
        value: 'first_comission_date',

      },
      {
        text: 'Montant de la première rémunération',
        align: 'left',
        value: 'first_comission_amount',

      },
      {
        text: 'Première rémunération payée',
        align: 'left',
        value: 'first_comission_paid',

      },
      {
        text: 'Prochaine date de rémunération',
        align: 'left',
        value: 'next_recurring_comission_date',

      },
      {
        text: 'Montant de la prochaine rémunération',
        align: 'left',
        value: 'recurring_comission_amount',
      },
      {
        text: 'Statut rémunération',
        align: 'left',
        value: 'recurring_comission_active',
      },
    ],
  }),
  watch: {
    comissionsOptions: {
      handler() {
        this.getResellerComissions()
      },
      deep: true,
    },
  },
  computed: {
    getTechSecurityLink() {
      return process.env.VUE_APP_TECHSECURITY_URL
    }
  },
  methods: {
    getResellerComissions() {
      this.loading = true;
      this.axios.get(process.env.VUE_APP_API_URL + "/resellerComissions?items_per_page=" + this.comissionsOptions.itemsPerPage + "&page=" + this.comissionsOptions.page)
          .then(response => {
            this.comissions = response.data.data;
            this.comissionsTotal = response.data.meta.total
            this.loading = false;

          }).catch(error => {
            this.loading = false;
            console.log(error.response)
          }
      );
    },
  },
  mounted() {

  },
  created() {
    this.getResellerComissions();
  }

}
</script>

<style>

</style>
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-app-bar
            app
            clipped-left
            color="blue"
            dense
    >
      <v-app-bar-nav-icon @click="handleDrawerToggle" class="white--text" v-if="isB2B"></v-app-bar-nav-icon>
      <v-toolbar-title class="mr-12 align-center">
          <span class="title white--text">Portail Partenaire TechSecurity : {{ type }}</span>
      </v-toolbar-title>
      <div class="flex-grow-1"></div>

      <!-- <v-btn
          rounded
          class="white--text"
          dark
          outlined
          @click="$router.push({name : 'portal'})"
      >
        changer de portail
      </v-btn> -->
        <v-btn icon @click="logout">
            <v-icon class="white--text">mdi-logout-variant</v-icon>
        </v-btn>
        <v-menu
                left
                bottom
                :close-on-content-click="false"
        >
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" dark>
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>

            <v-list >
                <v-list-item>
                    <v-list-item-title>
                        <v-switch
                                label="Dark Theme"
                                color="cyan"
                                class="d-flex pl-3"
                                v-model="$vuetify.theme.dark"
                        ></v-switch>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

    </v-app-bar>
</template>

<script>
    import axios from "axios";

    export default {
        methods: {
            handleDrawerToggle() {
                window.getApp.$emit("APP_DRAWER_TOGGLED");
            },
            logout() {
              axios.get(process.env.VUE_APP_API_URL + "/resellers/logout")
                  .then(() => {
                    this.$store.commit('setSessionToken', null);
                    this.$store.commit('setAuthenticated', false);
                    window.location.href = process.env.VUE_APP_TECHSECURITY_URL + "/reseller/login"
                    return true;
                  }).catch(() => {

                return false;
              });

            },
            switchType(){
              this.$store.commit('setType', this.switchTypeText);
            }
        },
        computed: {
          type (){
            return this.$store.state.type === 'b2b' ? 'Distribution Salariés des Entreprises' : 'Distribution Familles et TPE'
          },
          switchTypeText (){
            return this.$store.state.type === 'b2b' ? 'b2c' : 'b2b'
          },
          isB2B () {
            return this.$store.state.type === 'b2b';
          }
        }

    };
</script>

import store from '../vuex'

export default function auth({next, router}) {
    if(store.state.authenticated){
        return next();
    }else{
        window.location.href = process.env.VUE_APP_TECHSECURITY_URL + "/reseller/login"
        return router.push({name: 'login'});
    }
}

export default { 
      // STORE MUTATIONS
      setSessionToken(state, data){
            state.sessionToken = data
      },
      setAuthenticated(state, value) {
            state.authenticated = value;
      },
      setStripeLink(state, value) {
            state.stripe_link = value;
      },
      setType(state, value) {
            state.type = value;
      },
      setPortalCode(state, value) {
            state.portal_code = value;
      }
}
<template>
  <v-app>
    <v-app-bar
        app
        clipped-left
        color="blue"
        dense
    >
      <v-toolbar-title class="mr-12 align-center">
        <span class="title white--text">Portail Partenaire TechSecurity</span>
      </v-toolbar-title>
      <div class="flex-grow-1"></div>

    </v-app-bar>

    <v-row align="center" justify="center">
      <v-col xs="12" sm="8" md="7" class="px-4">
        <v-card class="elevation-12" outlined tile>
          <v-card-text class="pa-10">
            <h3 class="text-center black--text"> Choisissez le portail partenaire </h3>

            <div id="container" class="d-flex pa-10 col-12 justify-space-around">
              <div class="portal col-5 pb-10 pt-5 font-weight-bold d-flex flex-column" @click="goToPortal('b2c')">
                <div class="mb-1">
                  <v-icon x-large color="#495bf1">mdi-account</v-icon>
                </div>
                <div class="name">B2C</div>
                <div class="description">Distribution Familles et TPE</div>
              </div>
              <div class="portal col-5 pb-10 pt-5 font-weight-bold " @click="goToPortal('b2b')">
                <div class="mb-1">
                  <v-icon x-large color="#495bf1">mdi-domain</v-icon>
                </div>
                <div class="name">B2B</div>
                <div class="description">Distribution Salariés des Entreprises</div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
// import store from "@/vuex";
// import router from "@/router";

export default {
  data: function () {
    return {

    }
  },
  methods: {
    goToPortal(type){
      console.log("type: ", type);
      this.$store.commit('setType', type);
      this.$router.push({ name: 'dashboard' });
    }
  },
  created() {
    console.log("PORTAL");
  }
}
</script>

<style lang="scss">
  #container{
  }
  .portal{
    border: 2px solid #bbb;
    border-radius: 5px;
    cursor: pointer;
    &:hover{
      background-color: #edebeb8f;
      box-shadow: 0 0 5px #495bf1;
    }
    .image{
      color: #495bf1;
    }
    .name{
      font-weight: bold;
      font-size: 20px;
      color: black;
      font-family: Arial;
    }
    .description{

    }
  }
</style>
<template>
  <v-app>
    <v-app-bar
        app
        clipped-left
        color="blue"
        dense
    >
      <v-toolbar-title class="mr-12 align-center">
        <span class="title white--text">Portail Partenaire TechSecurity</span>
      </v-toolbar-title>
      <div class="flex-grow-1"></div>

    </v-app-bar>
    <v-main v-if="codeValidated">
      <v-container grid-list-xl fluid >
         <v-card
            outlined
            tile>
          <v-container grid-list-lg>
            <v-layout column class="pb-3">
              <v-flex xs6 md3 lg2 class="pb-0">
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date"
                        prepend-icon="mdi-calendar"
                        label="Month"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="date"
                      type="month"
                      no-title
                      scrollable
                      range
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <div style="clear-fix:both"></div>
               <v-flex col-12 class="pb-8">
                <LineChartGenerator
                    chart-id="my-chart-id"
                    :chart-options="totalsChartOptions"
                    :chart-data="totalsChartData"
          
                    :height="300"
                    :css-classes="''"
                    :styles="{}"
                  />
               </v-flex>
               <v-layout class="pb-3">
                <v-flex col-6 class="pb-8">
                  <Bar
                      chart-id="my-chart-2"
                      :chart-options="numbersChartOptions"
                      :chart-data="numbersChartData"
            
                      :height="300"
                      :css-classes="''"
                      :styles="{}"
                    />
                </v-flex>
                <v-flex col-6 class="pb-8">
                  <Bar
                      chart-id="my-chart-3"
                      :chart-options="comissionsChartOptions"
                      :chart-data="comissionsChartData"
            
                      :height="300"
                      :css-classes="''"
                      :styles="{}"
                    />
                </v-flex>
               </v-layout>
              <v-row wrap>
                <v-col v-for="type in subscriptionTypesFiltered" :key="type.value" class="col-6">
                  <v-card class="pa-3">
                    <h3> {{type.text}} </h3>
                    <div class="pa-2">
                      <ul>
                          <li v-for="cupon in typeSales(type.value)" :key="cupon.cupon + cupon.product"><strong>{{ cupon.name }}</strong> ({{cupon.cupon}}): {{ cupon.quantity }} ventes - {{ cupon.total }}€</li>
                      </ul>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-layout>
          </v-container>
        </v-card>
      </v-container>
      
    </v-main>
   <v-row align="center" justify="center" v-else>
      <v-col xs="12" sm="8" md="4" class="px-4">
        <v-card class="elevation-12" outlined tile>
          <v-toolbar dark color="blue">
            <v-toolbar-title>Insérer le code du portail</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-alert v-if="codeError" color="red" outlined type="error">{{ codeError }}</v-alert>
            <v-form ref="form">
              <v-text-field
                  id="code"
                  prepend-icon="lock"
                  name="code"
                  label="Code"
                  type="password"
                  v-model="code"
                  v-on:keyup.enter="saveCode()"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="loading" :loading="loading"
                   class="theme--dark btn btn-primary submit-btn" @click.prevent="saveCode()">
              Valider
            </v-btn>
            <v-dialog v-model="loading" persistent width="300">
              <v-card color="primary" dark>
                <v-card-text>
                  Validation de votre code...
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

  </v-app>
</template>

<script>

import { Line as LineChartGenerator, Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  LinearScale,
  CategoryScale,
  PointElement
)

// import store from "@/vuex";
// import router from "@/router";
const moment = require('moment');
export default {
  components: { LineChartGenerator, Bar },
  data: function () {
    return {
      loading: false,
      date: [moment().toISOString().substr(0, 7), moment().subtract(1, 'months').toISOString().substr(0, 7)],
      menu: false,
      subscriptionTypes: [
        {value: 'FAMILY_1', text: 'Family 1 Device'},
        {value: 'FAMILY_2', text: 'Family 3 Devices'},
        {value: 'FAMILY_3', text: 'Family 5 Devices'},
        {value: 'FAMILY_4', text: 'Family 10 Devices'},
        {value: 'PROFESSIONAL_1', text: 'Professional 5 Devices'},
        {value: 'PROFESSIONAL_2', text: 'Professional 10 Devices'},
        {value: 'PROFESSIONAL_3', text: 'Professional 20 Devices'},
      ],
      sales: [],
      totalsChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
         title: {
                display: true,
                text: 'Total Sales'
            }
        }
      },
      numbersChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
         title: {
                display: true,
                text: 'Number of sales per Cupon'
            }
        }
      },
      comissionsChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
         title: {
                display: true,
                text: 'Comissions of sales per Cupon'
            }
        }
      },
      salesData: null,
      chartLabels : [],
      code: '',
      codeValid: false,
      codeError: null,
    }
  },
  watch: {
    date(val){
      if(val.length === 2){
        this.getSales();
      }
      // this.getWholeSalers()
    }
  },
  computed: {
    codeValidated(){
      return this.portalCode && this.codeValid;
    },
    portalCode() {
      return this.$store.state.portal_code;
    },
    subscriptionTypesFiltered() {
      return this.subscriptionTypes.filter(t => this.typeSales(t.value).length);
    },
    sales_volume() {
      return this.datasets.sales;
    },
    comissions_volume() {
      return this.datasets.comissions;
    },
    comissions_sales_volume() {
      return this.datasets.comissions_sales;
    },
    chart_labels() {
      return this.chartLabels;
    },
    totalsChartData (){
      let numbersDataset = [];
      let comissionsDataset = [];
      let labels = [];
      if(this.salesData && this.salesData.length){
        let number_of_sales = {};
        let comission_sales = {};

        this.salesData.map(item => {
          if(!labels.includes(item.month_year)){
            labels.push(item.month_year);
          }

          // eslint-disable-next-line no-prototype-builtins
          if(!number_of_sales.hasOwnProperty(item.month_year)) number_of_sales[item.month_year] = 0;
          // eslint-disable-next-line no-prototype-builtins
          if(!comission_sales.hasOwnProperty(item.month_year)) comission_sales[item.month_year] = 0;
          number_of_sales[item.month_year] += item.quantity;
          comission_sales[item.month_year] += item.comissions_sales_volume;
        })

        labels = this.sortArrayByMonthYear(labels);

        const ordered_numbers = this.sortArrayByMonthYear(Object.keys(number_of_sales));
        const ordered_comissions = this.sortArrayByMonthYear(Object.keys(comission_sales));

        numbersDataset = ordered_numbers.map(n => number_of_sales[n]);
        comissionsDataset =  ordered_comissions.map(n => comission_sales[n]);
      }

       return {
          labels: labels,
          datasets: [ 
            {
              id: 'sales',
              label: 'Number of Sales',
              backgroundColor: 'green',
              borderColor: 'green',
              data: numbersDataset
            },
            { 
              id: 'comissions_sales',
              label: 'Comissions Volume',
              backgroundColor: 'yellow',
              borderColor: 'yellow',
              data: comissionsDataset
            } 
          ]
        }
    },
    numbersChartData (){
      let numbersDataset = {};
      let labels = [];
      const datasets = [];
      if(this.salesData && this.salesData.length){
        this.salesData.map(item => {
          const ref = item.name || item.code;
          const monthYear = item.month_year;
          if(!labels.includes(monthYear)){
            labels.push(monthYear);
          }

          // eslint-disable-next-line no-prototype-builtins
          if(!numbersDataset.hasOwnProperty(ref)) numbersDataset[ref] = {};
          // eslint-disable-next-line no-prototype-builtins
          if(!numbersDataset[ref].hasOwnProperty(monthYear)) numbersDataset[ref][monthYear] = 0;
          numbersDataset[ref][monthYear] += item.quantity;
        })
        labels = this.sortArrayByMonthYear(labels);
      }

      Object.entries(numbersDataset).map(([key, value]) => {
        const color = "#" + Math.floor(Math.random()*16777215).toString(16);
        datasets.push({
          id: key,
          label: key,
          backgroundColor: color,
          borderColor: color,
          // eslint-disable-next-line no-prototype-builtins
          data: labels.map(l => value.hasOwnProperty(l) ? value[l] : 0)
        });
      })

      return {
        labels: labels,
        datasets: datasets
      }
    },
    comissionsChartData (){
      let comissionsDataset = {};
      let labels = [];
      const datasets = [];
      if(this.salesData && this.salesData.length){
        this.salesData.map(item => {
          const ref = item.name || item.code;
          const monthYear = item.month_year;
          if(!labels.includes(monthYear)){
            labels.push(monthYear);
          }

          // eslint-disable-next-line no-prototype-builtins
          if(!comissionsDataset.hasOwnProperty(ref)) comissionsDataset[ref] = {};
          // eslint-disable-next-line no-prototype-builtins
          if(!comissionsDataset[ref].hasOwnProperty(monthYear)) comissionsDataset[ref][monthYear] = 0;
          comissionsDataset[ref][monthYear] += item.comissions_sales_volume;
        })
        labels = this.sortArrayByMonthYear(labels);
      }

      console.log("comissionsDataset: ", comissionsDataset);
      Object.entries(comissionsDataset).map(([key, value]) => {
        const color = "#" + Math.floor(Math.random()*16777215).toString(16);
        console.log("color:", color);
        datasets.push({
          id: key,
          label: key,
          backgroundColor: color,
          borderColor: color,
          // eslint-disable-next-line no-prototype-builtins
          data: labels.map(l => value.hasOwnProperty(l) ? value[l] : 0)
        });
      })
      console.log("datasets: ", datasets);
      return {
        labels: labels,
        datasets: datasets
      }
    },
  },
  methods: {
    typeSales(type){
      return (this.sales || []).filter(s => s.product === type)
    },
    getSales() {
      this.loading = true;
      const dateSorted = JSON.parse(JSON.stringify(this.date)).sort((a, b) => {
        const aSplit = a.split('-');
          const bSplit = b.split('-');
          if(parseInt(aSplit[0]) > parseInt(bSplit[0])) return 1;
          if(parseInt(aSplit[0]) < parseInt(bSplit[0])) return -1;

          if(parseInt(aSplit[1]) > parseInt(bSplit[1])) return 1;
          if(parseInt(aSplit[1]) < parseInt(bSplit[1])) return -1;

          return 0;
        } );
        console.log(dateSorted);
      const startDate = moment(dateSorted[0]);
      const endDate = moment(dateSorted[1]);
      this.axios.get(process.env.VUE_APP_API_URL + "/reseller/" + this.$route.params.reseller + "/sales/" + this.portalCode + "?startDate=" + startDate + "&endDate=" + endDate)
          .then(response => {
            this.sales = response.data.sales

            this.salesData = response.data.graphics;

            this.loading = false;

          }).catch(error => {
            this.loading = false;
            console.log(error.response);
            if(error.response.status === 403){
              this.codeValid = false;
              this.codeError = error.response.data.message
            }
          }
      );
    },
    sortArrayByMonthYear(arr) {
      return (arr || []).sort((a, b) => {
          const aSplit = a.split('-');
            const bSplit = b.split('-');
            if(parseInt(aSplit[0]) > parseInt(bSplit[0])) return 1;
            if(parseInt(aSplit[0]) < parseInt(bSplit[0])) return -1;

            if(parseInt(aSplit[1]) > parseInt(bSplit[1])) return 1;
            if(parseInt(aSplit[1]) < parseInt(bSplit[1])) return -1;

            return 0;
        })
    },
    saveCode(){
      this.$store.commit('setPortalCode', this.code);
      this.checkCode();
    },
    checkCode(){
      this.axios.get(process.env.VUE_APP_API_URL + "/reseller/" + this.$route.params.reseller + "/code/" + this.portalCode)
          .then(response => {
            if(response.data.success) this.codeValid = true;
            this.getSales();
          }).catch(err => {
            this.codeValid = false;
            this.codeError = err.response.data.message
            this.loading = false;
          }
      );
    }
  },
  created() {
    if(this.portalCode){
      this.checkCode();
    }
  }
}
</script>

<style lang="scss">
  
</style>